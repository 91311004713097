
import _ from 'lodash';
import { httpClient } from '~/utils/axios'
import { lesson, training_set } from '~/utils/models'
import Common from '~/mixins/common'
import Training from '~/mixins/training'
import Inbody from '~/mixins/inbody'
import DocumentInputs from '~/components/util/docs/DocumentInputs'
import VideoInputs from '~/components/util/videos/VideoInputs'
import EventSet from '~/components/common/lesson/EventSet'
import TrainingListSelector from '~/components/common/training_list/Selector'
import QrReader from '~/components/util/QrReader'
import TrainingListEdit from '~/components/common/training_list/Edit'
import TrainingEventSelector from '~/components/common/lesson/TrainingEventSelector'
import Tabs from '~/components/util/Tabs'
import GButton from '~/components/util/GButton'

export default {
  name: "LessonEdit",
  mixins: [Common,Training,Inbody],
  components: {
    DocumentInputs,
    VideoInputs,
    EventSet,
    TrainingListSelector,
    QrReader,
    TrainingListEdit,
    TrainingEventSelector,
    Tabs,
    GButton,
  },
  props: {
  },
  data() {
    return {
      mode: "create",
      tab: 0,
      tabOptions: [
        {label: "体組成・計測", value: 0, disabled: false},
        {label: "種目", value: 1, disabled: false},
        {label: "コメント・メディア", value: 2, disabled: false},
      ],

      view_mode: "bodyscan",
      edit_lesson: null,
      showModal: false,
      cp_docs: [],
      autoSaveOn: false,
      prevLesson: null,
      client_training_sets: [],
      client_history_videos: [],
    }
  },
  async mounted() {
    let self = this
  },
  watch: {
  },
  computed: {
    title(){
      return this.mode === "create" ? "自主トレ記録" : `${this.edit_lesson.date}の自主トレ`
    },
    showEventSetDetail:{
      get(){
        return this.$store.state.devise.showEventSetDetail
      },
      set(newVal){
        this.$store.commit("devise/setShowEventSetDetail", newVal)
      }
    },
  },
  methods: {
    // フォーカス
    focusNext(index) {
      let refName = `input${index + 1}`
      this.$refs[refName].focus();
    },
    // タブ
    changeTab(event){
      this.tab = event.newTabIndex
      if(this.tab === 0) this.view_mode = "bodyscan"
      if(this.tab === 1) this.view_mode = "training"
      if(this.tab === 2) this.view_mode = "comment"
    },

    // 外部から開く処理
    async openLessonEdit(edit_lesson = null, view_mode = "bodyscan"){
      if(edit_lesson){
        this.mode = "update"
        this.edit_lesson = _.cloneDeep(edit_lesson)
      }else{
        this.mode = "create"
        this.edit_lesson = _.cloneDeep(lesson)
      }

      // 種目ごとの履歴データも取得
      this.updateClientTrainingEvent()
      // // 前回トレーニング情報を取得
      // this.getPrevLesson()
      // マスタを最新化
      window.storeCtl.dispatch('auth/getTrainingEvents')
      
      if(view_mode === "bodyscan") this.tab = 0
      if(view_mode === "training") this.tab = 1
      if(view_mode === "comment") this.tab = 2
      this.view_mode = view_mode
      this.showModal = true
    },
    async updateClientTrainingEvent(){
      // console.log("updateClientTrainingEvent")
      let training_event_ids = _.map(this.edit_lesson.training_events.event_sets, "training_event_id")
      await httpClient
        .get(`/cli/floor/client_training_sets/get_by_event_ids.json`, {
          params: {
            training_event_ids: training_event_ids,
          }
        })
        .then(async (res) => {
          if (res.data.status === 'success') {
            this.client_training_sets = res.data.data.client_training_sets
            this.client_history_videos = res.data.data.client_videos
          } else {
            window.storeCtl.commit("alert/setError", res.data.message)
          }
        })
    },
    filterd_client_training_sets(training_event_id){
      let client_training_sets =  _.filter(this.client_training_sets, cts => {
        return cts.training_event_id === training_event_id
      })
      client_training_sets = _.sortBy(client_training_sets, ['date']).reverse()
      return client_training_sets
    },
    filterd_client_history_videos(training_event_id){
      let client_history_videos =  _.filter(this.client_history_videos, cv => {
        return cv.training_event_of_client_id === training_event_id
      })
      client_history_videos = _.sortBy(client_history_videos, ['created_at']).reverse()
      return client_history_videos
    },
    filterd_client_current_videos(training_event_id){
      let client_current_videos =  _.filter(this.edit_lesson.videos, vd => {
        return vd.training_event_of_client_id === training_event_id
      })
      client_current_videos = _.sortBy(client_current_videos, ['created_at']).reverse()
      return client_current_videos
    },

    // 前回トレーニングデータ ----------
    async getPrevLesson(){
      let self = this
      await httpClient
        .get(`/cli/floor/lessons/list_by_ids.json`, {params: {lesson_ids: this.client.prev_lesson_id}})
        .then(async (res) => {
          if (res.data.status === 'success') {
            self.prevLesson = res.data.data.lessons.length > 0 ? res.data.data.lessons[0] : null
          } else {
            self.$store.commit("alert/setError", res.data.message)
          }
        })
    },
    prevDiffClass(current_data, prev_data, wanna_gain){
      if(current_data === null) return "text-info"
      if(current_data > prev_data){
        return wanna_gain ? "text-success" : "text-danger"
      }else{
        return wanna_gain ? "text-danger" : "text-success"
      }
    },
    prevDiffData(current_data, prev_data, unit = "kg"){
      if(current_data === null || prev_data === null) return null
      const diff = _.round((current_data - prev_data), 1)
      if(current_data > prev_data){
        return `/ ${diff}${unit}増`
      }else{
        return `/ ${diff}${unit}減`
      }
    },

    // ファイル------
    docsUpdated(new_docs){
      // console.log("docsUpdated", new_docs)
      this.edit_lesson.docs = new_docs
      this.edit_lesson.training_docs.ids = _.map(new_docs, "id")
    },
    videosUpdated(new_videos){
      // console.log("docsUpdated", new_docs)
      this.edit_lesson.videos = new_videos
      this.edit_lesson.training_docs.video_ids = _.map(new_videos, "id")
    },
    eventSetVideoUpdated(updated_videos, training_event_id){
      // 元の動画
      let new_videos = []
      this.edit_lesson.videos.forEach(lv => {
        if(lv.training_event_of_client_id !== training_event_id) new_videos.push(lv)
      })
      new_videos = new_videos.concat(updated_videos)
      this.edit_lesson.videos = new_videos
      this.edit_lesson.training_docs.video_ids = _.map(new_videos, "id")
    },

    // 種目 ------------------
    // 種目ソート
    // 上に idx = 2 →　1
    sort(event){
      if(event.target_idx < 0 || (event.target_idx + 1) > this.edit_lesson.training_events.event_sets.length) return
      // console.log("sort",event.idx, event.target_idx)
      let new_event_sets = []
      let current_event_set = null
      // 自分以外を配列に入れて
      let index = 0
      this.edit_lesson.training_events.event_sets.forEach(es => {
        if( !es.sort_uid ) {
          // ソート用のUIDがない場合は新規作成
          es.sort_uid = this.generateUUID();
        }
        if(index === event.idx){
          current_event_set = es
        }else{
          new_event_sets.push(es)
        }
        index += 1
      })
      // 自分を特定を位置に入れる
      new_event_sets.splice(event.target_idx, 0, current_event_set)
      this.edit_lesson.training_events.event_sets = new_event_sets
    },
    // イベント選択イベント
    trainingEventSelected(training_event){
      let selectedTrainingEventCategory = _.find(this.categories, ["id", training_event.training_category_id])
      this.edit_lesson.training_events.event_sets.push({
        training_event_id: training_event.id,
        event_uid: training_event.uid,
        event: training_event,
        category: selectedTrainingEventCategory,
        training_sets: [_.cloneDeep(training_set)],
      })
    },
    // 複数選択
    trainingEventMultiSelected(training_events){
      training_events.forEach(te => {
        this.trainingEventSelected(te)
      })
      this.updateClientTrainingEvent()
    },
    // 種目追加
    addEventSets(event_sets){
      event_sets.forEach(es => {
        this.edit_lesson.training_events.event_sets.push(es)
      })
      this.updateClientTrainingEvent()
    },
    // 種目を追加して作成
    async updateTrainigMasterAndAddEvents(training_event){
      // マスタ更新
      await window.storeCtl.dispatch('company/getTrainingEvents')
      let selectedEvent = _.find(this.training_events, ["uid", training_event.uid])
      let selectedCategory = _.find(this.training_categories, ["id", selectedEvent.training_category_id])        
      this.edit_lesson.training_events.event_sets.push({
        training_event_id: training_event.id,
        event_uid: selectedEvent.uid,
        event: _.cloneDeep(selectedEvent),
        category: _.cloneDeep(selectedCategory),
        training_sets: [_.cloneDeep(training_set)],
      })
      this.updateClientTrainingEvent()
    },
    // 種目の削除
    removeTrainingEventAt(idx){
      let self = this
      self.$bvModal.msgBoxConfirm('この種目を削除してよろしいですか？', {
        okTitle: 'はい',
        cancelTitle: 'いいえ',
        centered: true,
      }).then(async confirm => {
        if(confirm){
          let new_event_sets = _.cloneDeep(self.edit_lesson.training_events.event_sets)
          new_event_sets.splice(idx, 1)
          self.edit_lesson.training_events.event_sets = []
          setTimeout(()=>{
            self.edit_lesson.training_events.event_sets = new_event_sets
          }, 10)
        }
      })
    },
    changeEventSet(change_event_set, index){
      let new_event_sets = _.cloneDeep(this.edit_lesson.training_events.event_sets)
      new_event_sets[index] = _.cloneDeep(change_event_set)
      this.edit_lesson.training_events.event_sets = new_event_sets
    },

    // 全体 -----------------------
    // 保存のみ
    async create(){
      let self = this
      self.$store.commit("loading/start")
      await httpClient
        .post(`/cli/floor/lessons/self_training_create.json`, {
          lesson: self.edit_lesson,
        })
        .then(async (res) => {
          if (res.data.status === 'success') {
            self.$router.push("/lessons/")
            self.$emit("updated", res.data.data.lesson)
            self.showModal = false
          } else {
            window.storeCtl.commit("alert/setError", res.data.message)
          }
        })
        .finally(()=>{
          window.storeCtl.commit("loading/stop")
        })
    },
    async update(){
      let self = this
      self.$store.commit("loading/start")
      return await httpClient
        .put(`/cli/floor/lessons/${self.edit_lesson.uid}/self_training_update.json`, {
          lesson: self.edit_lesson,
        })
        .then(async (res) => {
          if (res.data.status === 'success') {
            self.$router.push("/lessons/")
            self.$emit("updated", res.data.data.lesson)
            self.showModal = false
          } else {
            window.storeCtl.commit("alert/setError", res.data.message)
          }
        })
        .finally(()=>{
          window.storeCtl.commit("loading/stop")
        })
    },
  },
}
