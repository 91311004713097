export default function({ $gtm }) {
  setTimeout(()=> {
    // gtm
    let gtm_id = null

    // cmp
    if(window.storeCtl.state.auth.company && window.storeCtl.state.auth.company.gtm_code){
      gtm_id = window.storeCtl.state.auth.company.gtm_code
    }

    // trial
    if(window.storeCtl.state.public.company && window.storeCtl.state.public.company.gtm_code){
      gtm_id = window.storeCtl.state.public.company.gtm_code
    }
    
    if(gtm_id) $gtm.init(gtm_id)
  }, 2 * 1000)
}