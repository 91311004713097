const getDefaultState = () => {
  return {
    devise_token: null,
    isPC: true,
    isTouchDevice: false,
    drawer: false,

    firstContactDone: false,
    showFirstContactModal: false,

    tabChart: "body",

    // 予約時の表示日数
    selectableDays: 2,

    // トレーニング入力
    multiple_select_trainig_event: false,
    showEventSetDetail: true,

    // 複数アカウントログイン用
    // {company_uid: null, company_name: "A店", client_uid: 1, client_name: "山田", authToken: "aaaa"},
    authLists: [],
  }
}

export const state = () => (getDefaultState())
export const mutations = {
  // contact
  setDeviseToken(state, devise_token) {
    state.devise_token = devise_token
  },
  setIsPC(state, boolean) {
    state.isPC = boolean
  },
  setIsTouchDevice(state, boolean) {
    state.isTouchDevice = boolean
  },
  setDrawer(state, drawer) {
    state.drawer = drawer
  },  

  firstContactDone(state) {
    state.firstContactDone = true
  },
  setFirstContactModal(state, payload) {
    state.showFirstContactModal = payload
  },

  // top chart
  setTabChart(state, tabChart){
    state.tabChart = tabChart
  },

  setSelectableDays(state, selectableDays){
    state.selectableDays = selectableDays
  },

  // training
  setShowEventSetDetail(state, showEventSetDetail){
    state.showEventSetDetail = showEventSetDetail
  },
  setMultipleSelectTrainigEvent(state, multiple_select_trainig_event){
    state.multiple_select_trainig_event = multiple_select_trainig_event
  },

  // 多重ログイン
  addAuthList(state, account) {
    let authLists = []
    let isExist = false
    state.authLists.forEach(auth => {
      if(auth.client_uid === account.client_uid){
        authLists.push(account)
        isExist = true
      }else{
        authLists.push(auth)
      }
    })
    if(!isExist) authLists.push(account)
    state.authLists = authLists
  },  
  deleteAuthList(state, account) {
    state.authLists = _.filter(state.authLists, auth => {
      return auth.client_uid !== account.client_uid
    })
  },  
  // deleteAllAuthList(state) {
  //   state.authLists = []
  //   window.storeCtl.commit('auth/logout')
  // },  
}