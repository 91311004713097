
import { httpClient } from '~/utils/axios'
import _ from 'lodash';
import { training_list, training_set } from '~/utils/models'
import TrainingEventSelector from '~/components/common/lesson/TrainingEventSelector'
import EventSet from '~/components/common/lesson/EventSet'

export default {
  components: {
    TrainingEventSelector,
    EventSet,
  },
  props: {
    client: {type: Object, default: null},
  },
  data() {
    return {
      // edit
      edit_mode: null,
      showModal: false,
      modalTitle: null,
      edit_training_list: null,
    }
  },
  async mounted() {
    let self = this
  },
  computed: {
  },
  methods: {
    // 種目 ------------------
    // 種目ソート
    // eventSetDragged(){
    //   window.storeCtl.commit("loading/start")
    //   let newCurrentEventSets = _.cloneDeep(this.edit_training_list.training_events.event_sets)
    //   this.edit_training_list.training_events.event_sets = []
    //   setTimeout(() => {
    //     this.edit_training_list.training_events.event_sets = newCurrentEventSets
    //     window.storeCtl.commit("loading/stop")
    //   }, 10)
    // },
    sort(event){
      if(event.target_idx < 0 || (event.target_idx + 1) > this.edit_training_list.training_events.event_sets.length) return
      // console.log("sort",event.idx, event.target_idx)
      let new_event_sets = []
      let current_event_set = null
      // 自分以外を配列に入れて
      let index = 0
      this.edit_training_list.training_events.event_sets.forEach(es => {
        if( !es.sort_uid ) {
          // ソート用のUIDがない場合は新規作成
          es.sort_uid = this.generateUUID();
        }
        if(index === event.idx){
          current_event_set = es
        }else{
          new_event_sets.push(es)
        }
        index += 1
      })
      // 自分を特定を位置に入れる
      new_event_sets.splice(event.target_idx, 0, current_event_set)
      this.edit_training_list.training_events.event_sets = new_event_sets
    },
    // イベント選択イベント
    trainingEventSelected(training_evnet){
      let selectedTrainingEventCategory = _.find(this.categories, ["id", training_evnet.training_category_id])
      this.edit_training_list.training_events.event_sets.push({
        event_uid: training_evnet.uid,
        event: training_evnet,
        category: selectedTrainingEventCategory,
        training_sets: [_.cloneDeep(training_set)],
      })
    },
    // 複数選択
    trainingEventMultiSelected(training_evnets){
      training_evnets.forEach(te => {
        this.trainingEventSelected(te)
      })
    },
    // 種目を追加して作成
    async updateTrainigMasterAndAddEvents(training_event){
      // マスタ更新
      await window.storeCtl.dispatch('company/getTrainingEvents')
      let selectedEvent = _.find(this.training_events, ["uid", training_event.uid])
      let selectedCategory = _.find(this.training_categories, ["id", selectedEvent.training_category_id])        
      this.edit_training_list.training_events.event_sets.push({
        event_uid: selectedEvent.uid,
        event: _.cloneDeep(selectedEvent),
        category: _.cloneDeep(selectedCategory),
        training_sets: [_.cloneDeep(training_set)],
      })
    },
    // 種目の削除
    removeTrainingEventAt(idx){
      let self = this
      self.$bvModal.msgBoxConfirm('この種目を削除してよろしいですか？', {
        okTitle: 'はい',
        cancelTitle: 'いいえ',
        centered: true,
      }).then(async confirm => {
        if(confirm){
          self.edit_training_list.training_events.event_sets.splice(idx, 1);
        }
      })
    },
    changeEventSet(change_event_set, index){
      let new_event_sets = _.cloneDeep(this.edit_training_list.training_events.event_sets)
      new_event_sets[index] = _.cloneDeep(change_event_set)
      this.edit_training_list.training_events.event_sets = new_event_sets
    },
    newTrainingList(base_training_list){
      let new_training_list = _.cloneDeep(training_list)
      if(base_training_list) new_training_list = _.cloneDeep(base_training_list)
      this.edit_mode = "create"
      this.modalTitle = "新規作成"
      this.edit_training_list = new_training_list
      if(this.client) this.edit_training_list.client_id = this.client.id
      this.getClients()
      this.showModal = true
    },
    editTrainingList(training_list){
      this.edit_mode = "update"
      this.modalTitle = _.cloneDeep(training_list.name)
      this.edit_training_list = _.cloneDeep(training_list)
      this.getClients()
      this.showModal = true
    },
    async createTrainingList(){
      let self = this
      self.$store.commit("loading/start", true)
      await httpClient
        .post(`/cli/floor/training_lists.json`, {training_list: self.edit_training_list})
        .then(async (res) => {
          window.storeCtl.commit("loading/stop")
          if (res.data.status === 'success') {
            self.$emit("updated")
            window.storeCtl.commit("loading/stop")
            self.showModal = false
            window.storeCtl.commit("alert/setSuccess", "作成しました")
          } else {
            window.storeCtl.commit("loading/stop")
            window.storeCtl.commit("alert/setError", res.data.message)
          }
        })
        .finally(() => {
          window.storeCtl.commit("loading/stop")
        })
    },
    async updateTrainingList(){
      let self = this
      self.$store.commit("loading/start")
      await httpClient
        .put(`/cli/floor/training_lists/${self.edit_training_list.uid}.json`, {training_list: self.edit_training_list})
        .then(async (res) => {
          window.storeCtl.commit("loading/stop")
          if (res.data.status === 'success') {
            self.$emit("updated")
            window.storeCtl.commit("loading/stop")
            self.showModal = false
            window.storeCtl.commit("alert/setSuccess", "更新しました")
          } else {
            window.storeCtl.commit("loading/stop")
            window.storeCtl.commit("alert/setError", res.data.message)
          }
        })
        .finally(() => {
          window.storeCtl.commit("loading/stop")
        })
    },
  }
}
