
import { httpClient } from '~/utils/axios'
import axios from 'axios'
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'

export default {
  components: {
    VuePdfEmbed
  },
  props: {
    isInDocuments: {type: Boolean, default: false},
    showTool: {type: Boolean, default: true},
    showFullFileName: {type: Boolean, default: false},
    showImagePreview: {type: Boolean, default: true},
    doc_type: { type: String, default: "other" },
    client_id: { type: Number, default: null },
    canDelete: {type: Boolean, default: false},
    showCreatedAt: {type: Boolean, default: true},
    hasPrevPDF: {type: Boolean, default: false},
    hasNextPDF: {type: Boolean, default: false},

    doc: Object,
  },
  data() {
    return {
      isBusy: false,
      edit_doc: null,
      viewModal: false,

      src: null,
      pdfWidth: null,
      pdfHeight: null,
    }
  },
  async mounted() {
    let self = this
    this.edit_doc = _.cloneDeep(this.doc)
  },
  watch: {
    doc(new_val, old_val){
      this.edit_doc = _.cloneDeep(new_val)
    },
  },
  methods: {
    async viewPdf(){
      this.viewModal = true
    },
    zoomOut(){
      let eles = document.querySelectorAll('.vue-pdf-embed canvas')
      eles.forEach(ele => {
        ele.style.width = `${parseInt(ele.clientWidth / 1.2)}px`
        ele.style.height = `${parseInt(ele.clientHeight / 1.2)}px`
      })

    },
    zoomIn(){
      let eles = document.querySelectorAll('.vue-pdf-embed canvas')
      eles.forEach(ele => {
        ele.style.width = `${parseInt(ele.clientWidth * 1.2)}px`
        ele.style.height = `${parseInt(ele.clientHeight * 1.2)}px`
      })
    },

    clickImgae(){
      if(this.isInDocuments){
        this.$emit("showImageSlide", this.edit_doc)
      }else{
        this.$viewerApi({
          images: [this.edit_doc.img.url],
          options: {
            initialViewIndex: 0,
            movable: true,
            scalable: false,
            toolbar: {
              zoomIn: true,
              zoomOut: true,
              reset: true,
              prev: true,
              next: true,
              rotateRight: true,
              download: async () => {
                await httpClient
                  .get(`/cli/floor/docs/${this.edit_doc.uid}/get_image`,{responseType: "blob"})
                  .then(async (res) => {
                    window.storeCtl.commit("loading/stop")
                    var FILE = window.URL.createObjectURL(new Blob([res.data], { type: 'image/png' }));  
                    const a = document.createElement("a");
                    a.href = FILE
                    a.download = this.edit_doc.origin_file_name
                    a.click();
                  })
                  .finally(() => {
                    window.storeCtl.commit("loading/stop")
                  })
              }
            }
          },
        })
      }
    },
    async getDoc(){
      let self = this
      await httpClient
        .get(`/cli/floor/docs/${self.edit_doc.uid}.json`)
        .then(async (res) => {
          if (res.data.status === 'success') {
            self.edit_doc = _.cloneDeep(res.data.data.doc)
          } else {
            window.storeCtl.commit("alert/setError", res.data.message)
          }
        })
    },
    async getFile(){
      let self = this
      let file_name = self.edit_doc.origin_file_name
      // ファイル名変換
      // await self.getDoc()
      // if(self.edit_doc.client) file_name = `${self.edit_doc.client.name}--${self.edit_doc.origin_file_name}`
      // if(self.edit_doc.task_set) file_name = `${self.edit_doc.client.name}--${self.edit_doc.task_set.name}--${self.edit_doc.origin_file_name}`
      // if(self.edit_doc.task) file_name = `${self.edit_doc.client.name}--${self.edit_doc.task_set.name}--${self.edit_doc.task.name}--${self.edit_doc.origin_file_name}`

      let url = self.edit_doc.file.url ? self.edit_doc.file.url : self.edit_doc.img.url
      await axios
        .get(url, {
          responseType: 'blob',
        })
        .then(async (res) => {
          var FILE = window.URL.createObjectURL(new Blob([res.data]));  
          const a = document.createElement("a");
          a.href = FILE
          a.download = file_name
          a.click();
        })
    },
    async deleteFile(){
      let self = this
      self.$bvModal.msgBoxConfirm('削除してよろしいですか？', {
        centered: true,
        okTitle: 'はい',
        cancelTitle: 'いいえ',
      }).then(async confirm => {
        if(confirm){
          self.isBusy = true
          await httpClient
            .delete(`/cli/floor/docs/${self.doc.uid}.json`)
            .then(async (res) => {
              self.isBusy = false
              if (res.data.status === 'success') {
                self.$emit("deleted", self.doc)
              } else {
                window.storeCtl.commit("alert/setError", res.data.message)
              }
            })
            .finally(() => {
            })
        }
      })
    },
  }
}
