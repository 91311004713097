
import _ from 'lodash';
import { dayjsWapper } from '~/utils/tool'
import Common from '~/mixins/common'
import { httpClient } from '~/utils/axios'
import { client_trend } from '~/utils/models'
import DocumentInputs from '~/components/util/docs/DocumentInputs'
import GButton from '~/components/util/GButton'

export default {
  mixins: [Common],
  components: {
    DocumentInputs,
    GButton,
  },
  props: {
  },
  data() {
    return {
      target_date: dayjsWapper().format("YYYY-MM-DD"),
      edit_client_trend: null,
      menuModal: false,         
    }
  },
  async mounted() {
  },
  computed: {
    selectedDocs(){
      let docs = _.filter(this.edit_client_trend.docs, (d) => {
        return this.edit_client_trend.food_doc_ids.includes(d.id)
      }) 
      return docs
    },
  },
  methods: {
    openMenu(date){
      this.target_date = date ? date : dayjsWapper().format("YYYY-MM-DD")
      this.getClientTrend()
      this.menuModal = true
    },
    prevDate(){
      // 日付確認
      this.target_date = dayjsWapper(this.target_date).subtract(1,"d").format("YYYY-MM-DD")
      this.getClientTrend()
    },
    nextDate(){
      this.target_date = dayjsWapper(this.target_date).add(1,"d").format("YYYY-MM-DD")
      this.getClientTrend()
    },
    async getClientTrend(){
      let self = this
      window.storeCtl.commit("loading/start")
      await httpClient
      .get('/cli/floor/client_trends/trend.json', {params: {
        date: self.target_date,
      }})
      .then(async (res) => {
        if (res.data.status === 'success') {
          if(res.data.data.client_trend){
            self.edit_client_trend = res.data.data.client_trend
            self.edit_client_trend.client_rating = parseInt(self.edit_client_trend.client_rating)            
          }else{
            self.edit_client_trend = _.cloneDeep(client_trend)
          }
        } else {
          window.storeCtl.commit("alert/setError", res.data.message)
        }
      })
      .finally(() => {
        window.storeCtl.commit("loading/stop")
      })
    },
    async updateClientTrend(){
      let self = this
      window.storeCtl.commit("loading/start")
      await httpClient
      .put(`/cli/floor/client_trends/${self.edit_client_trend.id}/update_trend.json`, {
        date: self.target_date,
        client_trend: self.edit_client_trend,
      })
      .then(async (res) => {
        if (res.data.status === 'success') {
          self.edit_client_trend = res.data.data.client_trend
          self.$emit("update", res.data.data.client_trend)
          self.$store.commit("event/updateReload")
          self.menuModal = false
        } else {
          window.storeCtl.commit("alert/setError", res.data.message)
        }
      })
      .finally(() => {
        window.storeCtl.commit("loading/stop")
      })
    },

    updateDocs(new_docs){
      console.log("updateDocs",new_docs)
      this.edit_client_trend.docs = new_docs
      this.edit_client_trend.food_doc_ids = _.map(new_docs, 'id');
    },

  },
}
