

export default {
  components: {
  },
  props: {
    data_type: String,
  },
  data() {
    return {
    }
  },
  async mounted() {
  },
  computed: {
  },
  methods: {
  },
}
