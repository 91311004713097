
import { httpClient } from '~/utils/axios'
import _ from 'lodash';
import { scrollToTop } from '~/utils/tool'
import Tabs from '~/components/util/Tabs'
import LessonTypeChip from '~/components/common/lesson/TypeChip'
import LessonStatusChip from '~/components/common/lesson/StatusChip'
import LessonTrainingEvent from '~/components/common/lesson/TrainingEvent'
import Rating from '~/components/util/Rating'

export default {
  components: {
    LessonTypeChip,
    LessonStatusChip,
    LessonTrainingEvent,
    Tabs,
    Rating,
  },
  props: {
  },
  data() {
    return {
      // modal
      showModal: false,
      tabIndex: 0,
      tabOptions: [
        {label: "種目セット", value: 0, disabled: false},
        {label: "履歴", value: 1, disabled: false},
      ],

      // TrainingList
      freeword: null,
      training_lists: [],
      training_lists_pages: {
        total_count: 1,
        total_pages: 1,
        current_page: 1,
      },
      
      // 過去レッスン
      currentLessons: [],
      lesson_pages: {
        total_count: 1,
        total_pages: 1,
        current_page: 1,
      },
    }
  },
  async mounted() {
    let self = this
  },
  computed: {
  },
  methods: {
    changeTab(event){
      this.tabIndex = event.newTabIndex
      if(this.tabIndex === 0){
        this.getTrainingLists()
      }else{
        this.getLessons()
      }
    },
    open(tabIndex = 0){
      // 種目セット用
      this.trainingListSearchWord = null
      this.changeTab({newTabIndex: tabIndex})
      this.showModal = true
    },
    async getLessons(){
      let self = this
      // set params
      let status = ["done"]
      let params = {
        status_eq_any: status,
        page: self.lesson_pages.current_page,
        order: "desc",
      }
      window.storeCtl.commit("loading/start")
      await httpClient
        .get('/cli/floor/lessons.json', {params: params})
        .then(async (res) => {
          if (res.data.status === 'success') {
            self.currentLessons = res.data.data.lessons
            self.lesson_pages = res.data.data.pages
            scrollToTop()
          } else {
            window.storeCtl.commit("alert/setError", res.data.message)
          }
      })
      .finally(() => {
        window.storeCtl.commit("loading/stop")
      })
    },
    async getTrainingLists(){
      let self = this
      let url = `/cli/floor/training_lists.json?page=${self.training_lists_pages.current_page}`
      // if(self.freeword) url += `&q[name_or_client_name_cont]=${self.freeword}`
      window.storeCtl.commit("loading/start")
      await httpClient
        .get(url)
        .then(async (res) => {
          if (res.data.status === 'success') {
            self.training_lists = res.data.data.training_lists
            self.training_lists_pages = res.data.data.pages
            scrollToTop()
          } else {
            window.storeCtl.commit("alert/setError", res.data.message)
          }
      })
      .finally(() => {
        window.storeCtl.commit("loading/stop")
      })
    },
    useThisEventSet(event_sets){
      this.showModal = false
      this.$emit("selected", event_sets)
    },
  }
}
