
import _ from 'lodash';
import Common from '~/mixins/common'
import ActionBtn from '~/components/util/ActionBtn'

export default {
  mixins: [Common],
  components: {
    ActionBtn,
  },
  data() {
    return {
    }
  },
  async mounted() {
    let self = this
  },
  computed: {
    textColor(){
      return this.design_datas.footer_text_color
    },
  },
  methods: {
  },
}
