
import { httpClient } from '~/utils/axios'

export default {
  components: {
  },
  props: {
    isInDocuments: {type: Boolean, default: false},
    showTool: {type: Boolean, default: true},
    showFullFileName: {type: Boolean, default: false},
    doc_type: { type: String, default: "other" },
    client_id: { type: Number, default: null },
    canDelete: {type: Boolean, default: false},
    showCreatedAt: {type: Boolean, default: true},
    video: Object,
  },
  data() {
    return {
      isBusy: false,
      edit_video: null,
      viewModal: false,
      showVideo: false,
    }
  },
  async mounted() {
    let self = this
    this.edit_video = _.cloneDeep(this.video)
  },
  watch: {
    video(new_val, old_val){
      this.edit_video = _.cloneDeep(new_val)
    },
  },
  computed: {
  },
  methods: {
    openVideo(){
      this.showVideo = true
      setTimeout(()=>{
        this.$refs.videoPlayer.play()
      }, 500)
    },
    async deleteFile(){
      let self = this
      self.$bvModal.msgBoxConfirm('削除してよろしいですか？', {
        centered: true,
        okTitle: 'はい',
        cancelTitle: 'いいえ',
      }).then(async confirm => {
        if(confirm){
          self.isBusy = true
          await httpClient
            .delete(`/cli/floor/videos/${self.video.uid}.json`)
            .then(async (res) => {
              self.isBusy = false
              if (res.data.status === 'success') {
                self.$emit("deleted", self.video)
              } else {
                window.storeCtl.commit("alert/setError", res.data.message)
              }
            })
            .finally(() => {
            })
        }
      })
    },

  }
}
