
import Common from '~/mixins/common'
import UserIcon from '~/components/util/UserIcon'
import Alert from '~/components/util/Alert'
import Loading from '~/components/util/Loading'
import ConfirmDialog from '~/components/util/ConfirmDialog'
import FooterMenu from '~/components/util/FooterMenu'
import MultiAuth from '~/components/common/MultiAuth'
import AccountInfoModal from '~/components/common/AccountInfoModal'
import ActionBtn from '~/components/util/ActionBtn'

export default {
  name: "default",
  mixins: [Common],
  components: {
    Alert,
    Loading,
    ConfirmDialog,
    UserIcon,
    FooterMenu,
    MultiAuth,
    AccountInfoModal,
    ActionBtn,
  },
  data() {
    return {
    }
  },
  async mounted() {
    let self = this
    self.$nuxt.$root.$confirm = this.$refs.confirm

    // detect devise
    if(self.$mq === 'pc'){
      console.log("this is pc")
      this.$store.commit("devise/setDrawer", true)
      this.$store.commit("devise/setIsPC", true)   
    }else{
      console.log("this is sp")
      this.$store.commit("devise/setIsPC", false) 
    }

  },
  computed: {
    drawer: {
      get(){
        return this.$store.state.devise.drawer
      },
      set(newValue){
        this.$store.commit("devise/setDrawer", newValue)
      }
    },
    // design
    headerTextColor(){
      return this.design_datas.header_text_color
    },
    sideTextColor(){
      return this.design_datas.side_text_color
    },
  },
  methods: {
    clickDrower(){
      this.$store.commit("devise/setDrawer", !this.$store.state.devise.drawer)
    },
    async reload(){
      let self = this
      self.$store.commit("loading/start")
      self.$store.commit("event/updateReload")
      await Promise.all([
        self.$store.dispatch("auth/getUpdate"),
        self.$store.dispatch("auth/getDashboard"),
        self.$store.dispatch('auth/getClient'),
        self.$store.dispatch("auth/updateOneSignalDeviseToken"),
      ])
      self.updateDesign()
      self.$store.commit("loading/stop")
    }
  },
}
