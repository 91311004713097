

export default {
  name: "reciept",
  components: {
  },
  data() {
    return {
    }
  },
}
