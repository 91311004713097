
import Common from '~/mixins/common'
import Alert from '~/components/util/Alert'
import Loading from '~/components/util/Loading'
import ConfirmDialog from '~/components/util/ConfirmDialog'

export default {
  name: "trial",
  mixins: [Common],
  components: {
    Alert,
    Loading,
    ConfirmDialog,
  },
  data() {
    return {
      version: !!process.env.VERSION ? Number(process.env.VERSION) : 0,
    }
  },
  async mounted() {
    let self = this
    self.$nuxt.$root.$confirm = this.$refs.confirm

    // detect devise
    if(self.$mq === 'pc'){
      console.log("this is pc")
      this.$store.commit("devise/setDrawer", true)
      this.$store.commit("devise/setIsPC", true)   
    }else{
      console.log("this is sp")
      this.$store.commit("devise/setIsPC", false) 
    }

  },
  computed: {
  },
  methods: {
  },
}
