import { httpClient } from '~/utils/axios'

export default ({ store, redirect }) => {
  httpClient.interceptors.request.use(
    (config) => {
      if (store.getters['auth/isLogin']) {
        config.headers = { Authorization: store.state.auth.authToken }
      }
      return config
    },
    (error) => {
      if (error.response && error.response.status === 500) {
        alert('通信エラーが発生しました')
        return Promise.reject(error)
      }
    }
  )

  httpClient.interceptors.response.use(
    (res) => {
      console.log("interceptors", res.data.status)
      if (res.data.status === 'success') return res
      if(res.data.status === 'not_authenticated') store.commit("auth/logout")
      if(res.data.message) store.commit("alert/setError", res.data.message)
      return res
    },
    (error) => {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      return Promise.reject(error);
    });
}
