import { httpClient } from '~/utils/axios'
import _ from 'lodash';
import { dayjsWapper } from '~/utils/tool'

const getDefaultState = () => {
  return {
    // contact
    firstContact: false,

    // auth
    authToken: null,
    company: null,
    settings: null,
    client: null,
    goal_term: null,
    design_datas: {
      img_logo: {},
      img_header: {},
      img_side: {},
      img_bk: {},
      img_trial: {},
      img_trial_bk: {},
      primary_color: "#2F54EB",
      header_color: "#1D1E1F",
      side_color: "#1D1E1F",
      body_color: "#F7F9FC",
      footer_color: "#FFFFFF",
      trial_color: "#F7F9FC",
      header_text_color: "#FFFFFF",
      side_text_color: "#FFFFFF",
      body_text_color: "#0A1233",
      footer_text_color: "#7A8099",
      trial_text_color: "#0A1233",
    },
    chat_room: null,
    chat_member: null,
    inprogressTaskSets: [],
    new_alert_size: 0,

    // カレンダー周り
    calendars: [],
    menus: [],
    courses: [],
    items: [],
    courses: [],
    item_categories: [],
    items: [],
    training_categories: [],
    training_events: [],
    training_machines: [],
    training_muscles: [],
    client_training_events: [],
    time_zone: null,
    survey: null,

    // ライフログ
    showLogChart: true,

    // 種目
    trainingEventDoneOnly: false,

    // dashboard
    prev_lesson: null,
    next_lesson: null,
    prev_has_data_lesson: null,
    prev_has_data_client_trend: null,

    // push
    lastUpdatedAtClient: null,
    oneSignalDeviseToken: null,
  }
}

export const state = () => (getDefaultState())
export const mutations = {
  // contact
  firstContactDone(state, payload) {
    state.firstContact = true
  },
  
  // auth
  setAuthToken(state, auth_token) {
    state.authToken = auth_token
  },
  setSettings(state, settings) {
    state.settings = settings
  },
  setCompany(state, company) {
    console.log("setCompany")
    if(state.company == null || state.company.design_updated_at !== company.design_updated_at){
      state.design_datas.img_logo = company.img_logo
      state.design_datas.img_header = company.img_header
      state.design_datas.img_side = company.img_side
      state.design_datas.img_bk = company.img_bk
      state.design_datas.img_trial = company.img_trial
      state.design_datas.img_trial_bk = company.img_trial_bk
      state.design_datas.primary_color = company.design_settings.primary_color
      state.design_datas.header_color = company.design_settings.header_color
      state.design_datas.side_color = company.design_settings.side_color
      state.design_datas.body_color = company.design_settings.body_color
      state.design_datas.footer_color = company.design_settings.footer_color
      state.design_datas.trial_color = company.design_settings.trial_color
      state.design_datas.header_text_color = company.design_settings.header_text_color
      state.design_datas.side_text_color = company.design_settings.side_text_color
      state.design_datas.body_text_color = company.design_settings.body_text_color
      state.design_datas.footer_text_color = company.design_settings.footer_text_color
      state.design_datas.trial_text_color = company.design_settings.trial_text_color
    }
    state.company = company
  },
  selectChatRoom(state, payload) {
    state.chat_room = payload.chat_room
    state.chat_member = payload.chat_member
  },
  setCalendars(state, calendars) {
    calendars = _.sortBy(calendars, "sort")
    state.calendars = calendars
  },
  setCourses(state, courses) {
    courses.forEach(c => {
      c.label = `${c.name}/${c.ticket_number}枚/${c.price}円`
    })
    courses = _.sortBy(courses, "sort")
    state.courses = courses
  },
  setMenus(state, menus) {
    menus.forEach(m => {
      m.label = `${m.name}/${m.need_ticket_size}枚/${m.max_seat_size}人`
    })
    menus = _.sortBy(menus, "sort")
    state.menus = menus
  },
  setItemCategories(state, item_categories) {
    item_categories = _.sortBy(item_categories, ["sort"])
    state.item_categories = item_categories
  },
  setItems(state, items) {
    items.forEach(it => {
      it.label = `${it.name_inner} / ${it.price}円(${it.tax_rate}%) / ${it.manage_stock ? '在庫:' + it.stock_size + '個' : null}`
    })
    items = _.sortBy(items, ["sort"])
    state.items = items
  },
  setTrainingCategories(state, training_categories) {
    training_categories = _.sortBy(training_categories, ["sort"])
    state.training_categories = training_categories
  },
  setTrainingEvents(state, training_events) {
    training_events = _.orderBy(
      training_events,
      ['sort', 'id'],
      ['asc', 'desc']
    )
    state.training_events = training_events
  },
  setClientTrainingEvents(state, client_training_events) {
    state.client_training_events = client_training_events
  },
  setTrainingMuscles(state, training_muscles) {
    training_muscles = _.orderBy(
      training_muscles,
      ['sort', 'id'],
      ['asc', 'desc']
    )
    state.training_muscles = training_muscles
  },
  setTrainingMachines(state, training_machines) {
    training_machines = _.orderBy(
      training_machines,
      ['sort', 'id'],
      ['asc', 'desc']
    )
    state.training_machines = training_machines
  },
  setTimeZone(state, time_zone) {
    state.time_zone = time_zone
  },
  clearSurvey(state){
    state.survey = null
  },
  setSurvey(state, survey){
    state.survey = survey
  },
  setShowLogChart(state, showLogChart){
    state.showLogChart = showLogChart
  },
  setTrainingEventDoneOnly(state, trainingEventDoneOnly){
    state.trainingEventDoneOnly = trainingEventDoneOnly
  },
  setClient(state, client) {
    state.client = client
  },
  setGoalTerm(state, goal_term) {
    state.goal_term = goal_term
  },
  setDashboard(state, payload) {
    state.prev_lesson = payload.prev_lesson
    state.next_lesson = payload.next_lesson
    state.prev_has_data_lesson = payload.prev_has_data_lesson
    state.prev_has_data_client_trend = payload.prev_has_data_client_trend
  },
  setLastUpdatedAt(state, payload){
    state.lastUpdatedAtClient = payload
  },
  setNewAlertSize(state, new_alert_size) {
    state.new_alert_size = new_alert_size
  },
  setOneSignalDeviseToken(state, oneSignalDeviseToken) {
    state.oneSignalDeviseToken = oneSignalDeviseToken
  },
  clearAllData(state) {
    state.authToken = null
    state.client = null
    // Object.assign(state, getDefaultState())
    window.storeCtl.commit("client/reset")
  },
  logout(state) {
    $nuxt.$router.push(`/login/`)
    Object.assign(state, getDefaultState())
    window.storeCtl.commit("client/reset")
  },

}
export const getters = {
  isLogin(state) {
    return !!state.authToken
  },
}

// 戻り地はisBeforeRequest
export const actions = {
  async refreshToken(context) {
    await httpClient
      .post('/cli/account/refresh_token.json')
      .then(async res => {
        if (res.data.status === 'success') {
          if(res.data.data.is_admin === false) context.commit('setAuthToken', res.data.data.auth_token)
          console.log("refreshToken",res.data.data.expire_at)
        } else {
          window.storeCtl.commit('alert/setError', res.data.message)
        }
      })
  },

  async getUpdate(context){
    await httpClient
    .get('/cli/has_update.json')
    .then(async (res) => {
      if (res.data.status === 'success') {
        // alert size
        context.commit("setNewAlertSize", res.data.data.new_alert_size)

        // マスタの更新が新しければマスタGET
        let latest_update_at = dayjsWapper(res.data.data.client_updated_at)
        let last_update_at = dayjsWapper(context.state.lastUpdatedAtClient)
        if(context.state.lastUpdatedAtClient === null || latest_update_at.isAfter(last_update_at)){
          context.commit("setLastUpdatedAt", res.data.data.client_updated_at)

          // ここで一度マスターをすべて取得
          await window.storeCtl.dispatch('auth/getClient')
        }
      } else {
        window.storeCtl.commit("alert/setError", res.data.message)
      }
    })
    .finally(() => {
      // window.storeCtl.commit("loading/stop")
    })
  },

  async clearAppBatch(){
    if(!window.storeCtl.state.userAgent.isIosApp) return
    try{
      var json = {
        "call_name": "clearBadge",
        "timestamp": dayjsWapper().format("YYYY-MM-DD HH:mm:ss")
      }  
      var json_str = JSON.stringify(json)
      window.webkit.messageHandlers.clearBadge.postMessage(json_str);
    }catch(e){
    }
  },

  //// push
  // ログインしていて
  // LocalStrage取れていて
  // StoreとLocalstrageの値が違う
  // サーバー同期処理
  async updateOneSignalDeviseToken(context){
    const onesignal_user_id = localStorage.getItem('onesignal_user_id'); 
    if(
      onesignal_user_id &&
      window.storeCtl.state.auth.oneSignalDeviseToken !== onesignal_user_id
    ){
      await httpClient
      .post('/cli/account/update_onesignal_device_token.json', {
        onesignal_device_token: onesignal_user_id
      })
      .then((res) => {
        if (res.data.status === 'success') {
          window.storeCtl.commit("auth/setOneSignalDeviseToken", onesignal_user_id)
        } else {
          window.storeCtl.commit("alert/setError", res.data.message)
        }
      })
    }
  },

  // マスタ一式取得
  async getClient(context){
    await httpClient
    .get('/cli/account/show.json')
    .then(async (res) => {
      if (res.data.status === 'success') {
        context.commit("setSettings", res.data.data.settings)
        context.commit("setCompany", res.data.data.company)
        context.commit("setCalendars", res.data.data.calendars)
        context.commit("setClient", res.data.data.client)
        context.commit("setGoalTerm", res.data.data.goal_term)
        context.commit("setCourses", res.data.data.courses)
        context.commit("setMenus", res.data.data.menus)
        context.commit("setItemCategories", res.data.data.item_categories)
        context.commit("setItems", res.data.data.items)
        context.commit("setTrainingCategories", res.data.data.training_categories)
        context.commit("setTrainingEvents", res.data.data.training_events)
        context.commit("setTrainingMuscles", res.data.data.training_muscles)
        context.commit("setTrainingMachines", res.data.data.training_machines)
        context.commit("setTimeZone", res.data.data.time_zone)
      } else {
        window.storeCtl.commit("alert/setError", res.data.message)
      }
    })
  },

  async getReserveMaster(context){
    await httpClient
    .get('/cli/account/show_reserve_master.json')
    .then(async (res) => {
      if (res.data.status === 'success') {
        context.commit("setSettings", res.data.data.settings)
        context.commit("setCompany", res.data.data.company)
        context.commit("setCalendars", res.data.data.calendars)
        context.commit("setClient", res.data.data.client)
        context.commit("setMenus", res.data.data.menus)
        context.commit("setTimeZone", res.data.data.time_zone)
      } else {
        window.storeCtl.commit("alert/setError", res.data.message)
      }
    })
  },

  // レッスンデータ取得
  async getDashboard(context){
    await httpClient
    .get('/cli/account/dashboard.json')
    .then(async (res) => {
      if (res.data.status === 'success') {
        context.commit("setCompany", res.data.data.company)
        context.commit("setClient", res.data.data.client)
        context.commit("setDashboard", {
          prev_lesson: res.data.data.prev_lesson,
          next_lesson: res.data.data.next_lesson,
          prev_has_data_lesson: res.data.data.prev_has_data_lesson,
          prev_has_data_client_trend: res.data.data.prev_has_data_client_trend,
        })
      } else {
        window.storeCtl.commit("alert/setError", res.data.message)
      }
    })
  },

  // survey
  async getSurveyByScene(context, scene){
    context.commit("clearSurvey")
    await httpClient
      .get(`/cli/floor/surveys/get_by_scene.json?use_scene=${scene}`)
      .then(async (res) => {
        if (res.data.status === 'success') {
          let survey = res.data.data.survey
          let survey_questions = res.data.data.survey_questions
          if(survey) survey.survey_questions = _.sortBy(survey_questions, "sort")
          context.commit("setSurvey", survey)
        } else {
          window.storeCtl.commit("alert/setError", res.data.message)
        }
      })
  },
  async getSurveyById(context, uid){
    context.commit("clearSurvey")
    await httpClient
      .get(`/cli/floor/surveys/${uid}.json`)
      .then(async (res) => {
        if (res.data.status === 'success') {
          let survey = res.data.data.survey
          let survey_questions = res.data.data.survey_questions
          survey.survey_questions = _.sortBy(survey_questions, "sort")
          context.commit("setSurvey", survey)
        } else {
          window.storeCtl.commit("alert/setError", res.data.message)
        }
      })
  },

  async getTrainingEvents(context){
    await httpClient
      .get(`/cli/floor/training_events.json`)
      .then(async (res) => {
        if (res.data.status === 'success') {
          context.commit("setTrainingCategories", res.data.data.training_categories)
          context.commit("setTrainingEvents", res.data.data.training_events)
          context.commit("setTrainingMuscles", res.data.data.training_muscles)
          context.commit("setTrainingMachines", res.data.data.training_machines)
          context.commit("setClientTrainingEvents", res.data.data.client_training_events)
        } else {
          window.storeCtl.commit("alert/setError", res.data.message)
        }
      })
  },

}
