export default function({ route, store, redirect }) {
  if(!store.getters['auth/isLogin']){
    // http://localhost:3000/?campaign_uid=CC_4083995754のCampagin_uidを引き継ぐ
    let query_params = route.fullPath.split("?")
    let url = query_params.length > 0 ? `/login/?${query_params[1]}` : "login/"
    return redirect(url)
  }

  // clientかどうかで処理
  // console.log("$route.fullPath",route.fullPath)
  // if(store.state.auth.client.status !== "active"){
  //   if(!route.fullPath.startsWith("/client/")) return redirect("/client/")
  // }

}
