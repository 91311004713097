
import LessonTypeChip from '~/components/common/lesson/TypeChip'

export default {
  components: {
    LessonTypeChip,
  },
  props: {
    training_event: Object,
    client_training_set: Object,
  },
  data() {
    return {
    }
  },
  async mounted() {
  },
  methods: {
  },
}
