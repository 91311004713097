export const state = () => ({
  isIosApp: false,
  ua: null,
})
export const mutations = {
  set(state, payload) {
    state.isIosApp = payload.isIosApp
    state.ua = payload.ua
  }
}
