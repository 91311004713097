
import { httpClient } from '~/utils/axios'
import _ from 'lodash';
import VueQrcode from '@chenfengyuan/vue-qrcode';

export default {
  middleware: ['authenticated'],
  components: {
    VueQrcode,
  },
  data() {
    return {
      showModal: false,
      token: null,
    }
  },
  methods: {
    async openIDCard(){
      this.token = null
      this.$store.commit("loading/start")
      await httpClient
        .get('/cli/account/checkin_token.json')
        .then(async (res) => {
          this.$store.commit("loading/stop")
          if (res.data.status === 'success') {
            this.token = res.data.data.token
            this.showModal = true
          } else {
            self.$store.commit("alert/setError", res.data.message)
          }
        })
    },
  }
}
