import axios from "axios";

async function check(store){

  // ログイン時のみ対応
  if(store.getters['auth/isLogin']){
    // デターの同期チェック
    store.dispatch("auth/getUpdate")

    // トラッキングコード同期
    store.dispatch("tracking_code/sync")

    // oneSignal
    store.dispatch("auth/updateOneSignalDeviseToken")

  } // ログイン 

  // アプリ・バージョンアップ
  try {
    // version.jsonファイルから最新のバージョンを取得
    const res = await axios.get("/version.json");
    const data = res.data;
    const latestVersion = data.version;

    // 環境変数から現在のバージョンを取得
    const curVersion = !!process.env.VERSION ? Number(process.env.VERSION) : 0;
    // 最新バージョンよりも古かったら、強制的にリロードする
    if (curVersion < latestVersion){
      window.location.reload(true);
    }else{
    }
  } catch(error) {
    console.error(error);
    // エラーのときはなにもしない
  }
}

// まずSettimeoutで初期チェック、その後1分毎に自動チェック
export default async ({store, route, query}) => {

  // トラッキングコードセット
  // トラッキングコードがなければOtherにセット
  if(route.name === "user-uuid"){
    store.commit("tracking_code/setCode", `c_${route.params.uuid}`)
  }else if(route.name === "post-uuid"){
    store.commit("tracking_code/setCode", `p_${route.params.uuid}`)
  }else if(route.name === "tag-name"){
    store.commit("tracking_code/setCode", `t_${route.params.name}`)
  }else if(query.tc){
    store.commit("tracking_code/setCode", query.tc)
  }else{
    store.commit("tracking_code/setCode", `other`)
  }

  // useragnet
  window.setTimeout(async () => {
    // check useragent
    const ua = navigator.userAgent
    let isIosApp = !!ua.match(/iOS_App/)
    store.commit('userAgent/set', {isIosApp: isIosApp, ua: ua})
    let isTouchDevice = false
    if(/android/i.test(ua)) isTouchDevice = true
    if(/iPad|iPhone|iPod/i.test(ua) || isIosApp) isTouchDevice = true
    store.commit('devise/setIsTouchDevice', isTouchDevice)
  }, 500)

  // 起動時だと処理できない箇所があったので５秒後
  window.setTimeout(async () => {
    // 諸々のチェック
    check(store)

    // 認証データのリフレッシュ
    if (store.getters['auth/isLogin']) store.dispatch('auth/refreshToken')
  }, 1000 * 5);

  // 5分に1回
  window.setInterval(() => {check(store)}, 1000 * 60 * 5);
}