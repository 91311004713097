import { render, staticRenderFns } from "./reciept.vue?vue&type=template&id=eb764df2&scoped=true&lang=pug"
import script from "./reciept.vue?vue&type=script&lang=js"
export * from "./reciept.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "eb764df2",
  null
  
)

export default component.exports