
import _ from 'lodash';

export default {
  components: {
  },
  props: {
    initTab: {type: [String, Number], default: 0},
    tabOptions: Array,
  },
  data() {
    return {
      currentTab: null,
    }
  },
  async mounted() {
    this.currentTab = this.initTab 
  },
  computed: {
  },
  methods: {
    activeColor(tab){
      if(tab.value === this.currentTab){
        return this.$store.state.auth.design_datas.primary_color
      }else{
        return this.$store.state.auth.design_datas.body_text_color
      }
    },
    click(tab){
      if(tab.disabled) return
      let old_tab_value = _.cloneDeep(this.currentTab)
      this.currentTab = tab.value
      this.$emit("change", {
        oldTabIndex: old_tab_value,
        newTabIndex: tab.value
      })
    },
  },
}
