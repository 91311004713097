
import GButton from '~/components/util/GButton'

export default {
  layout: "empty",
  components: {
    GButton,
  },
  props: {
    error: {
      type: Object,
      default: null
    }
  },
  computed: {
  },
  methods: {
    goToTop() {
      window.location = "/"
    },
  }
}
