
import Common from '~/mixins/common'
export default {
  mixins: [Common],
  components: {
  },
  props: {
    size: {type: String, default: "md"},
    to: {type: String, default: null},
    block: {type: Boolean, default: false},
    disabled: {type: Boolean, default: false},
    variant: {type: String, default: "primary"},
  },
  data() {
    return {
    }
  },
  computed: {
    primaryBgColor(){
      if(this.variant === 'primary'){
        return this.primaryGradationBgColor
      }else{
        return null
      }
    },
    primaryColor(){
      if(this.variant === 'primary'){
        return this.$store.state.auth.design_datas.primary_color
      }else{
        return null
      }
    },
  },
  async mounted() {
  },
}
