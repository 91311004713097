const getDefaultState = () => {
  return {
    company: null,
    design_datas: {
      img_logo: {},
      img_header: {},
      img_side: {},
      img_bk: {},
      img_trial: {},
      img_trial_bk: {},
      primary_color: "#2F54EB",
      header_color: "#1D1E1F",
      side_color: "#1D1E1F",
      body_color: "#F7F9FC",
      footer_color: "#FFFFFF",
      trial_color: "#F7F9FC",
      header_text_color: "#FFFFFF",
      side_text_color: "#FFFFFF",
      body_text_color: "#0A1233",
      footer_text_color: "#7A8099",
      trial_text_color: "#0A1233",
    },
    survey: null,
  }
}

export const state = () => (getDefaultState())
export const mutations = {
  setCompany(state, company) {
    console.log("setCompany")
    if(state.company == null || state.company.design_updated_at !== company.design_updated_at){
      state.design_datas.img_logo = company.img_logo
      state.design_datas.img_header = company.img_header
      state.design_datas.img_side = company.img_side
      state.design_datas.img_bk = company.img_bk
      state.design_datas.img_trial = company.img_trial
      state.design_datas.img_trial_bk = company.img_trial_bk
      state.design_datas.primary_color = company.design_settings.primary_color
      state.design_datas.header_color = company.design_settings.header_color
      state.design_datas.side_color = company.design_settings.side_color
      state.design_datas.body_color = company.design_settings.body_color
      state.design_datas.footer_color = company.design_settings.footer_color
      state.design_datas.trial_color = company.design_settings.trial_color
      state.design_datas.header_text_color = company.design_settings.header_text_color
      state.design_datas.side_text_color = company.design_settings.side_text_color
      state.design_datas.body_text_color = company.design_settings.body_text_color
      state.design_datas.footer_text_color = company.design_settings.footer_text_color
      state.design_datas.trial_text_color = company.design_settings.trial_text_color
    }
    state.company = company
  },
  clearSurvey(state){
    state.survey = null
  },
  setSurvey(state, survey){
    state.survey = survey
  },
  clearAllData(state) {
    Object.assign(state, getDefaultState())
    window.storeCtl.commit("client/reset")
  },
}
// 戻り地はisBeforeRequest
export const actions = {
}
