
import _ from 'lodash';
import { httpClient } from '~/utils/axios'
import Common from '~/mixins/common'
import ClientTrendForm from '~/components/common/client_trend/Form'
import IDCard from '~/components/common/IDCard'
import SmartLock from '~/components/util/SmartLock'
import LessonEdit from '~/components/common/lesson/Edit'

export default {
  mixins: [Common],
  components: {
    ClientTrendForm,
    IDCard,
    SmartLock,
    LessonEdit,
  },
  props: {
  },
  data() {
    return {
    }
  },
  async mounted() {
    let self = this

  },
  computed: {
  },
  methods: {
    async smart_lock_open(){
      let self = this
      if(this.company.smartlock_type === "sesami"){
        self.$store.commit("loading/start")
        await httpClient
          .post('/cli/account/open_door.json')
          .then(async (res) => {
            self.$store.commit("loading/stop")
            if (res.data.status === 'success') {
              self.$store.commit("alert/setSuccess", "解錠しました")
            } else {
              self.$store.commit("alert/setError", res.data.message)
            }
          })
          .finally(() => {
            self.$store.commit("loading/stop")
          })
      }else if(this.company.smartlock_type === "remotelock"){
        this.$refs.smart_lock.open()
      }
    },
    // captureScreen(){
    //   const element = document.querySelector('body');

    //   html2canvas(element).then((canvas) => {
    //     // ここでキャンバスを画像として処理
    //     this.saveImage(canvas);
    //   });
    // },
    // saveImage(canvas) {
    //   // キャンバスを画像に変換
    //   const image = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");

    //   // ダウンロードリンクを生成し、自動的にクリックすることで画像をダウンロード
    //   let downloadLink = document.createElement('a');
    //   downloadLink.href = image;
    //   downloadLink.download = 'screenshot.png';
    //   document.body.appendChild(downloadLink);
    //   downloadLink.click();
    //   document.body.removeChild(downloadLink);
    // },
  }
}
