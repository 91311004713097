import Vue from 'vue';
import { dayjsWapper } from '~/utils/tool'

let rateFormat = function(num) {
  return `${parseInt(num * 100)}%`
}
let numberFormat = function(num) {
  return (num || 0)
    .toString()
    .replace(/^-?\d+/g, m => m.replace(/(?=(?!\b)(\d{3})+$)/g, ","));
}
let moneyFormat = function(num) {
  return (
    "￥" +
    (num || 0)
      .toString()
      .replace(/^-?\d+/g, m => m.replace(/(?=(?!\b)(\d{3})+$)/g, ","))
  );
}
let byteToMbFormat = function(num) {
  if(num === null) return "-"
  let mb = _.round(parseFloat(num / (1024 * 1024)), 1)
  return (mb || 0)
    .toString()
    .replace(/^-?\d+/g, m => m.replace(/(?=(?!\b)(\d{3})+$)/g, ","));
}
let uppercaseFirst = function(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
let truncate = function(value, length) {
  if(value === null) return ""
  var length = length ? parseInt(length) : 20;
  var ommision = '...'

  if (value.length <= length) {
    return value;
  }

  return value.substring(0, length) + ommision;
}
let removeTag = function(str){
  if(!str) return null
  return str.replace(/(<([^>]+)>)/gi, '')
}
let timeToYYYYMMDDWeekday = function(time){
  if(!time) return "-"
  return dayjsWapper(time).format("YYYY/MM/DD(ddd)")
}
let timeToYYMMDDHHMM = function(time){
  if(!time) return "-"
  return dayjsWapper(time).format("YY/MM/DD HH:mm")
}
let timeToYYYYMMDDHHMM = function(time){
  if(!time) return "-"
  return dayjsWapper(time).format("YYYY/MM/DD HH:mm")
}
let timeToYYYYMM = function(time){
  if(!time) return "-"
  return dayjsWapper(time).format("YYYY/MM")
}
let timeToYYYYMMDD = function(time){
  if(!time) return "-"
  return dayjsWapper(time).format("YYYY/MM/DD")
}
let timeToYYMMDD = function (time) {
  if (!time) return '-'
  return dayjsWapper(time).format('YY/MM/DD')
}
let timeToMMDD = function(time){
  if(!time) return "-"
  return dayjsWapper(time).format("MM/DD")
}
let timeToMMDDWeekday = function(time){
  if(!time) return "-"
  return dayjsWapper(time).format("M/D(dd)")
}
let timeToHHMM = function(time){
  if(!time) return "-"
  return dayjsWapper(time).format("HH:mm")
}
let booleanToCanCanot = function(boolean){
  return boolean ? "可" : "不可"
}

let statusToText = function(status){
  if(status === "before") return "処理前"
  if(status === "inprogress") return "進行中"
  if(status === "done") return "完了"
  if(status === "canceled") return "キャンセル"
}
let checkBlank = function(data){
  if(data === null || data === "") return "-"
  return data
}
let actionCheckIcon = function(data){
  return data ? "◯" : data === false ? "✕" : "-"
}
let actionCheckText = function(data){
  return data ? "出来た" : data === false ? "出来なかった" : "-"
}
let training_event_log_text = function (status) {
  if (status === 'not_good') return '苦手'
  if (status === 'good') return '上手'
  if (status === 'very_good') return '非常に上手'
  if (status === 'done') return '完了'
  return '未設定'
}

let pofToText = function (type) {
  if (type === 'mid') return 'ミッドレンジ'
  if (type === 'contract') return 'コントラクト'
  if (type === 'stretch') return 'ストレッチ'
  if (type === 'other') return 'その他'
  return '未設定'
}

Vue.filter('rateFormat', rateFormat)
Vue.filter('numberFormat', numberFormat)
Vue.filter('byteToMbFormat', byteToMbFormat)
Vue.filter('moneyFormat', moneyFormat)
Vue.filter('uppercaseFirst', uppercaseFirst)
Vue.filter('truncate', truncate)
Vue.filter('removeTag', removeTag)
Vue.filter('timeToYYYYMMDDWeekday', timeToYYYYMMDDWeekday)
Vue.filter('timeToYYMMDDHHMM', timeToYYMMDDHHMM)
Vue.filter('timeToYYYYMMDDHHMM', timeToYYYYMMDDHHMM)
Vue.filter('timeToYYYYMM', timeToYYYYMM)
Vue.filter('timeToYYYYMMDD', timeToYYYYMMDD)
Vue.filter('timeToYYMMDD', timeToYYMMDD)
Vue.filter('timeToMMDD', timeToMMDD)
Vue.filter('timeToMMDDWeekday', timeToMMDDWeekday)
Vue.filter('timeToHHMM', timeToHHMM)
Vue.filter('booleanToCanCanot', booleanToCanCanot)
Vue.filter('statusToText', statusToText)
Vue.filter('checkBlank', checkBlank)
Vue.filter('actionCheckIcon', actionCheckIcon)
Vue.filter('actionCheckText', actionCheckText)
Vue.filter('training_event_log_text', training_event_log_text)
Vue.filter('pofToText', pofToText)