import _ from 'lodash';

const getDefaultState = () => {
  return {
    lessons: [],
  }
}

export const state = () => (getDefaultState())
export const mutations = {
  reset(state) {
    Object.assign(state, getDefaultState())
  },

  setTaskSets(state, lessons) {
    state.lessons = lessons
  },
  
}
export const getters = {
}
export const actions = {
}
